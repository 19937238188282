body{
  margin: 0;
  background-color: #fff;
  font-family: Favoritfreshly,sans-serif;
  color: #333;
  font-size: .875rem;
  line-height: 1.43em;
  font-weight: 400;
}
.main-wrpa {
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.left-part, .right-part {
  width: 40%;
  flex: 0 0 auto;
}

.right-part {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.header.footer {
  /* background: #15478e; */
  background: #fff;
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
}

.left-part1{
  width: 100%; 
}

.adwait-logo{
  width: 10%;
}


@media (max-width: 767px) {
.header{
  justify-content: space-around;
}
  .left-part, .right-part {
      width: 50%;
  }

  .left-part1{
    width: 100%;
  }

  .right-part {
      justify-content: center;
      padding-top: 2px;
  }

  .header.footer .left-part {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .chakra-form-control {
      width: 100% !important;
  }

  .left-part1{
    width: 100%;
    
  }
  

}
