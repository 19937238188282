.backg {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent),
    url(../../assets/freshly.jpg);
  z-index: -1;
  background-size: 100%;
}
.carrot {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent),
    url(../../assets/carrot.jpg);
  z-index: -1;
  background-size: 100%;
  padding: 1.5rem;
  /* background-repeat: no-repeat; */
}

.bottom2 {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent),
    url(../../assets/mix.jpg);
  z-index: -1;
  background-size: 65%;
  background-position: right bottom;
  background-repeat: no-repeat;
  padding: 7rem;
}

.animate {
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}

.animate Image {
  width: 100%;
  transition: 0.5s all ease-in-out;
  display: none;
}

.animate:hover Image {
  transform: scale(1.5);
}

.lower_side {
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.5), transparent),
    url(../../assets/fridge.jpg);
  z-index: -1;
  background-size: 65%;
  background-position: left bottom;
  background-repeat: no-repeat;
  padding: 7rem;
}
